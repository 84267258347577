import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Table = makeShortcode("Table");
const TableHead = makeShortcode("TableHead");
const TableRow = makeShortcode("TableRow");
const TableCell = makeShortcode("TableCell");
const TableBody = makeShortcode("TableBody");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Events power alerting for certificate lifecycle activities.
Used to help administrators operate the Smallstep Platform,
Events can be generated and surfaced via standard mechanisms.
Events are a feature of the commercial `}<a parentName="p" {...{
        "href": "https://smallstep.com/certificate-manager/"
      }}>{`Smallstep Certificate Manager`}</a>{` offering.`}</p>
    <h1>{`Webhook Events`}</h1>
    <p>{`Webhook Events allow for real-time logging of SSH sessions, `}</p>
    <p>{`certificate creation, and certificate expirations events.
You can use Webhook Events to:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Alert on certificate expiry`}</strong>{` - Prevent certificate expiry related outages with proactive notifications on upcoming expirations.`}</li>
      <li parentName="ul"><strong parentName="li">{`Stream events to monitoring solutions`}</strong>{` - Push detailed certificate information to third-party monitoring and alerting systems.`}</li>
      <li parentName="ul"><strong parentName="li">{`Monitor certificate operations`}</strong>{` - Ensure your automated certificate toolchain is operating as expected.`}</li>
    </ul>
    <h2>{`Webhook Event Types`}</h2>
    <p>{`We currently have four webhook event types, namely:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`SSH`}</strong>{`: Sends a webhook event for SSH start and stop sessions and user change events.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Expiration`}</strong>{`: Sends a webhook event for Certificate Manager when a certificate is within the last 25% of its lifetime, and again when it expires.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Certificate`}</strong>{`: Sends a webhook event for Certificate Manager with basic certificate details whenever a certificate is created or expires.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`All`}</strong>{`: Sends events for everything above, for both SSH and Certificate Manager.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Note`}</strong>{`: `}<em>{`We do not send certificate expiration events for OIDC certificates, to minimize noise, since they're usually short-lived. However, certificate creation events for such certificates will always be sent.`}</em></p>
    <h2>{`Using Webhook Events`}</h2>
    <p>{`To register a webhook, go to Settings > Webhooks and fill out the form as shown below:`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/webhook-events-demo.png",
        "alt": "image",
        "title": "Webhook Events Requirements"
      }}></img></p>
    <h2>{`Webhook Events Response Formats`}</h2>
    <p>{`Here are examples of the response data you should expect to receive for each webhook event type:`}</p>
    <p><strong parentName="p">{`SSH 'session start' event`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "category": "ssh-session",
  "hostname": "example-host.example.com",
  "username": "tester",
  "id": "d0547bbd-aab6-4531-b809-868f60d0bda7",
  "timestamp": "2022-08-01 20:29:23.333679027 +0000 UTC",
  "type": "start"
}
`}</code></pre>
    <p><strong parentName="p">{`SSH 'session end' event`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "category": "ssh-session",
  "hostname": "example-host.example.com",
  "username": "tester",
  "id": "d0547bbd-aab6-4531-b809-868f60d0bda7",
  "timestamp": "2022-08-01 20:32:14.816883566 +0000 UTC",
  "type": "end"
}
`}</code></pre>
    <p><strong parentName="p">{`SSH 'user change' event`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "category": "ssh-session",
  "hostname": "example-host.example.com",
  "username": "root",
  "id": "d0547bbd-aab6-4531-b809-868f60d0bda7",
  "timestamp": "2022-08-01 20:31:47.771260977 +0000 UTC",
  "type": "user_change"
}
`}</code></pre>
    <p>{`Where: `}</p>
    <Table mdxType="Table">
    <TableHead mdxType="TableHead">
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">Parameter</TableCell>
            <TableCell mdxType="TableCell">Description</TableCell>
        </TableRow>
    </TableHead>
    <TableBody mdxType="TableBody">
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">category</TableCell>
            <TableCell mdxType="TableCell">The webhook event type. One of `ssh-session`, `certificate-request`, or `certificate-expiration`. </TableCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">hostname</TableCell>
            <TableCell mdxType="TableCell">The name of the registered host </TableCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">username</TableCell>
            <TableCell mdxType="TableCell">The posix username of the user responsible for the ssh session</TableCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">id</TableCell>
            <TableCell mdxType="TableCell">The smallstep ssh session id </TableCell>
        </TableRow>
         <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">timestamp</TableCell>
            <TableCell mdxType="TableCell">The time at which the event occurred</TableCell>
        </TableRow>
         <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">type</TableCell>
            <TableCell mdxType="TableCell">The specific type of SSH event type</TableCell>
        </TableRow>
    </TableBody>
    </Table>
    <p><strong parentName="p">{`Certificate creation event`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "category": "certificate-request",
  "authorityId": "5c780190-4e4e-4c5a-9331-50be29b28e21",
  "serialNumber": "217736770851791642908500119529647273613",
  "id": "4f2f0c29-704a-46f8-8f90-f7f74675b169",
  "notBefore": "2022-08-01T20:36:51.000Z",
  "notAfter": "2022-08-01T20:42:51.000Z",
  "certificate": "-----BEGIN CERTIFICATE-----\\nMIICITCCAcegAwIBAgIQOyXzxXEspOAfi/10+hPMLjAKBggqhkjOPQQDAjAhMR8w\\nHQYDVQQDExZKV0sgU0EgSW50ZXJtZWRpYXRlIENBMB4XDTIyMDgwMTIwMzY1MVoX\\nDTIyMDgwMTIwNDI1MVowFDESMBAGA1UEAxMJbXlzZXJ2aWNlMFkwEwYHKoZIzj0C\\nAQYIKoZIzj0DAQcDQgAEfDkIN/DlpolcxVqMu5iZ0zJqVlAXfasCJvGxwBJKpQdS\\nZJOLsenrguwJJpLqNuj3icIhI6gFodqvLpNAcaJ/zKOB7TCB6jAOBgNVHQ8BAf8E\\nBAMCB4AwHQYDVR0lBBYwFAYIKwYBBQUHAwEGCCsGAQUFBwMCMB0GA1UdDgQWBBRM\\nO8Ak/HRgQ9j8iIK4xgONh+/W8DAfBgNVHSMEGDAWgBSNeT/nLC3Z+iRpVXVdvgpH\\nlJRzwjArBgNVHREEJDAigiBteXNlcnZpY2UuaW50ZXJuYWwubXljb21wYW55Lm5l\\ndDBMBgwrBgEEAYKkZMYoQAEEPDA6AgECBA9hdXRob3JpdHktYWRtaW4EJGQzMTc0\\nMjM2LTE1YzEtNDEzOC04OTJkLTVlZDIzNDcxN2M5YTAKBggqhkjOPQQDAgNIADBF\\nAiEA5kXI0dB6xMnEMAiwEigy0vYMHXYvX97BderaMFGdmSECIH2FQ/5A7RyxdkbE\\nNVOdaxxFAW8YBCiyiKxnu0BzOM+C\\n-----END CERTIFICATE-----\\n",
  "subject": {
    "commonName": "myservice",
    "country": "",
    "organization": "",
    "organizationalUnit": "",
    "locality": "",
    "province": "",
    "street": "",
    "state": "",
    "email": ""
  },
  "sans": [
    {
      "type": "DNS",
      "value": "myservice.internal.mycompany.net"
    }
  ]
}
`}</code></pre>
    <p><strong parentName="p">{`Certificate expiring/expired event`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "category": "certificate-expiration",
  "authorityId": "5c780190-4e4e-4c5a-9331-50be29b28e21",
  "serialNumber": "204736770851791642908500119529647273613",
  "id": "4a0d055f-8ac2-463f-b090-0e940fadc0c3",
  "notBefore": "2022-08-01T20:48:20.000Z",
  "notAfter": "2022-08-01T20:54:20.000Z",
  "certificate": "-----BEGIN CERTIFICATE-----\\nMIICHzCCAcSgAwIBAgIRAJoG3iYtwd9xSbAsRjDMdo0wCgYIKoZIzj0EAwIwITEf\\nMB0GA1UEAxMWSldLIFNBIEludGVybWVkaWF0ZSBDQTAeFw0yMjA4MDEyMDQ4MjBa\\nFw0yMjA4MDEyMDU0MjBaMBQxEjAQBgNVBAMTCW15c2VydmljZTBZMBMGByqGSM49\\nAgEGCCqGSM49AwEHA0IABNfWJtHDDXlvfiljmzzgNdzmmk5Xhxf84RiP9DShOXxI\\nNgndPXYp7d49UCVKm3IrJ3HY/6WBh2g/cv0p2BQ3kUejgekwgeYwDgYDVR0PAQH/\\nBAQDAgeAMB0GA1UdJQQWMBQGCCsGAQUFBwMBBggrBgEFBQcDAjAdBgNVHQ4EFgQU\\nNgNvb8W9k/5/PHNQYx+xaQ45faQwHwYDVR0jBBgwFoAUjXk/5ywt2fokaVV1Xb4K\\nR5SUc8IwKwYDVR0RBCQwIoIgbXlzZXJ2aWNlLmludGVybmFsLm15Y29tcGFueS5u\\nZXQwSAYMKwYBBAGCpGTGKEABBDgwNgIBAQQEbmFtZQQrQ09pRDNLMzE1Mms3TW5Q\\nNDR2eWlaLXNZaVphMmtSZU9fWFRNRG54RTB3VTAKBggqhkjOPQQDAgNJADBGAiEA\\nuWabixlFwJif3+ypPdJax5xPwCPT+LxL2ETNP0RwGvICIQC/fdBn7b+W+sa9GSLx\\nDFNysUCX39uO7F7XbAEJBRFa8A==\\n-----END CERTIFICATE-----\\n",
  "subject": {
    "commonName": "myservice",
    "country": "",
    "organization": "",
    "organizationalUnit": "",
    "locality": "",
    "province": "",
    "street": "",
    "state": "",
    "email": ""
  },
  "sans": [
    {
      "type": "DNS",
      "value": "myservice.internal.mycompany.net"
    }
  ]
}
`}</code></pre>
    <p>{`Where: `}</p>
    <Table mdxType="Table">
    <TableHead mdxType="TableHead">
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">Parameter</TableCell>
            <TableCell mdxType="TableCell">Description</TableCell>
        </TableRow>
    </TableHead>
    <TableBody mdxType="TableBody">
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">category</TableCell>
            <TableCell mdxType="TableCell">The specific webhook event type. </TableCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">authorityId</TableCell>
            <TableCell mdxType="TableCell">The ID of the certificate authority responsible for the issuance of the created, about to expire, or already expired certificate.</TableCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">serialNumber</TableCell>
            <TableCell mdxType="TableCell">The serial number of the certificate </TableCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">id</TableCell>
            <TableCell mdxType="TableCell">The Smallstep UUID of the certificate</TableCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">notBefore</TableCell>
            <TableCell mdxType="TableCell">The time before which the certificate is ineligible for use.</TableCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">notAfter</TableCell>
            <TableCell mdxType="TableCell">The time after which the certificate will no longer be valid (i.e, expiration time)</TableCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">certificate</TableCell>
            <TableCell mdxType="TableCell">The PEM encoded format of the certificate</TableCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">subject</TableCell>
            <TableCell mdxType="TableCell">An object containing details of the entity that the certificate was issued for.</TableCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableCell mdxType="TableCell">sans</TableCell>
            <TableCell mdxType="TableCell">An array of the details of the certificate's subject alternative names</TableCell>
        </TableRow>
    </TableBody>
    </Table>
    <h2>{`Deleting Webhook Events`}</h2>
    <p>{`If you no longer have need for a webhook and wish to delete it:  `}</p>
    <ol>
      <li parentName="ol">{`Go to `}<strong parentName="li">{`Settings > Webhooks`}</strong></li>
      <li parentName="ol">{`Click on the webhook's name`}</li>
      <li parentName="ol">{`Click on `}<strong parentName="li">{`Unregister this webhook`}</strong>{` as shown below: `}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/graphics/unregister-webhook.png",
        "alt": "image",
        "title": "Webhook Events Requirements"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      